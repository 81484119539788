import API from '../global/Resource';
import HTTP from '../global/SafeRequest';

export default {
    detail(param){
        return HTTP.get(API.urls.product.detail, param);
    },
    searchProduct(param){
        return HTTP.get(API.urls.product.search, param);
    },
    brandProduct(param){
        return HTTP.get(API.urls.product.brandProduct, param);
    },
    category(){
        return HTTP.get(API.urls.product.category);
    },
    searchMakeup(param) {
        return HTTP.get(API.urls.makeup.search, param);
    },
    makeupDetail(param) {
        return HTTP.get(API.urls.makeup.detail, param);
    },
    searchBrand(param) {
        return HTTP.get(API.urls.brand.search, param);
    },
    brandDetail(param) {
        return HTTP.get(API.urls.brand.detail, param);
    },
    hotMakeup(param) {
        return HTTP.get(API.urls.makeup.hot, param)
    }
}
