<template>
  <div class="main">
    <div class="banner">
      <img class="banner-img" ref="bannerImg" v-image="info.img_head_url" />
      <router-link :to='"/product/brand?id="+brand.id'>
        <div class="brand">
          <div class="brand-img-div">
            <img class="brand-img" v-image="brand.logo_img" :default-img="defaultSmallImage" :error-img="defaultSmallImage" />
          </div>
          <div class="brand-name">{{ brand.chinese_name }}</div>
        </div>
      </router-link>

    </div>

    <div class="info">
      <div class="base">
        <p class="zh-title">{{ info.name }}</p>
        <p class="en-title">{{info.name_en}}</p>
        <div class="check-skin">
          <van-tag plain type="warning" size="large">测肤质</van-tag>
        </div>
        <div class="price">
          参考价：<span v-if="info.price>0">￥{{info.price}}/{{info.capacit}}</span>
          <span v-else>--</span>
        </div>
        <div class="tags effects">
          <van-tag plain type="warning" size="large" v-for="effect in info.effects" :key="effect.id">{{ effect.name }}</van-tag>
        </div>
      </div>
      <div class="more-info" @click="showExtraInfo">
        产品信息：版本、备案文号、生产地等
        <van-icon name="arrow" color="#f00" style="float:right; margin-top:11px" />
      </div>
    </div>

    <div class="makeup">
      <div class="head">安全与功效</div>
      <div>
        <van-row gutter="15">
          <van-col span="12">
            <div class="risk-col">
              <div class="effect-row" v-for="risk in info.risk_num" :key="risk">
                <span class="label">{{ risk.name }}：</span> <span class="value">{{ risk.count }} 种</span>
              </div>
            </div>
          </van-col>
          <van-col span="12">
            <div class="effect-col">
              <div class="effect-row" v-for="effect in info.effect_makeup" :key="effect">
                <span class="label">{{ effect.name }}：</span> <span class="value">{{ effect.count }} 种</span>
              </div>
            </div>
          </van-col>
        </van-row>
      </div>
    </div>
    <div class="all-makeup">
      <div class="head">
        全部成分（{{makeupList.length}}）
        <van-icon @click="makeupHelp" class="help" name="question-o" />
      </div>
      <table class="makeup-table" border="1">

        <tr style="position: sticky; top: 0px;">
          <th>成分</th>
          <th>风险分</th>
          <th>活性</th>
          <th>致痘</th>
        </tr>
        <tr v-for="makeup in makeupList" :key="makeup.id" @click="makeupClick(makeup)">
          <td class="col1">
            {{ makeup.display_name }}
            <br/>
            <div class="desc">{{ makeup.target_name }}</div>
          </td>
          <td class="col2">
            <van-tag size="medium" v-if="makeup.risk>=0 && makeup.risk<=2" type="success">{{ makeup.risk }}</van-tag>
            <van-tag size="medium" v-if="makeup.risk>=3 && makeup.risk<=5" type="warning">{{ makeup.risk }}</van-tag>
            <van-tag size="medium" v-if="makeup.risk>=6 && makeup.risk<=9" type="danger">{{ makeup.risk }}</van-tag>
          </td>
          <td class="col3">
            <svg v-if="makeup.is_active==1" class="iconfont" aria-hidden="true">
              <use xlink:href="#icon-fenzi"></use>
            </svg>
          </td>
          <td class="col4">
            <svg v-if="makeup.is_acne==1" class="iconfont" aria-hidden="true">
              <use xlink:href="#icon-shenhejinduyuandian"></use>
            </svg>
          </td>
        </tr>
      </table>
    </div>
    <div style="height: 50px;">
    </div>
    <div class="van-safe-area-bottom"></div>
  </div>

  <van-popup v-model:show="extraInfoVisible" position="bottom" :style="{ height: '60%' }">
    <div class="extra-info">
      <van-row class="row van-hairline--bottom">
        <van-col span="6" class="label">备案号</van-col>
        <van-col span="18" class="content">{{ info.approval }}</van-col>
      </van-row>
      <van-row class="row van-hairline--bottom" v-if="brand.area">
        <van-col span="6" class="label">生产地</van-col>
        <van-col span="18" class="content">{{ brand.area }}</van-col>
      </van-row>
      <van-row class="row van-hairline--bottom" v-if="info.manufacturer">
        <van-col span="6" class="label">生产企业</van-col>
        <van-col span="18" class="content">{{ info.manufacturer }}</van-col>
      </van-row>
      <van-row class="row van-hairline--bottom" v-if="brand.introduce">
        <van-col span="6" class="label">品牌介绍</van-col>
        <van-col span="18" class="content">{{ brand.introduce }}</van-col>
      </van-row>
    </div>
  </van-popup>
</template>

<script>

import {
  ref,
  onActivated,
  onDeactivated
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {Icon, Tag, Popup, Col, Row, Image} from 'vant';
import Product from "../../models/Product";

export default {
  components: {
    [Icon.name]: Icon,
        [Tag.name]:Tag,
        [Popup.name]:Popup,
        [Col.name]:Col,
        [Row.name]:Row,
        [Image.name]:Image
  },
  setup() {
    const route = useRoute();

    let info = ref({});
    let makeupList = ref([]);
    let brand = ref({});
    const defaultSmallImage = require('@/assets/images/common/default-small@128.png')

    onActivated(() => {
      let id = route.query.id;
      let param = {
        'id': id
      }
      Product.detail(param).then(res => {
        info.value = res.data.info
        makeupList.value = res.data.makeup;
        brand.value = res.data.brand;
        // document.title = res.data.detail.title
      });
    });
    onDeactivated(() => {
      console.log('leave product')
    })
    const router = useRouter()
    return {
      info,
      makeupList,
      brand,
      router,
      defaultSmallImage
    };
  },
  data() {
    return {
      extraInfoVisible: false
    }
  },
  methods: {
    showExtraInfo() {
      this.extraInfoVisible = true;
    },
    makeupClick(data) {
      this.router.push('/product/makeup?id='+data.id)
    },
    makeupHelp() {
      this.router.push('/product/makeup_introduce')
    }
  }
};
</script>
<style>
.makeup-table .van-tag {
  position: unset;
}
</style>
<style scoped>

.banner {
  position: relative;
  text-align: center;
  background-image: linear-gradient(#fbfbfb, #f6f6f6);
  padding: 30px 0 10px 0;
}
.banner-img {
  width: 180px;
  height: 180px;
  border: none;
}

.brand {
  position: absolute;
  left: 15px;
  top: 30px;
  border-radius: 25px;
  opacity: 0.5;
  background-color: black;
  height: 46px;
}
.brand-img-div {
  background-color: #fff;
  position: absolute;
  left: 3px;
  top: 3px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.brand-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.brand-name {
  margin-left: 50px;
  margin-right: 20px;
  padding: 0 5px;
  vertical-align: middle;
  height: 46px;
  line-height: 46px;
  color: #fff;
}

.info {
  background-color: #fff;
  margin-top: 5px;
  padding: 0 15px;
  border-radius: 15px 15px 0 0;
}
.info .base {
  position: relative;
  padding-top: 15px;
}
.zh-title {
  font-weight: 700;
  font-size: 20px;
  margin-right: 75px;
}
.check-skin {
  position: absolute;
  top: 38px;
  right: 10px;
}
.info .base .tags {
  margin: 8px 0;
}
.info .effects .van-tag {
  margin: 4px 15px 4px 0;
}
.more-info {
  margin-top: 10px;
  border-top: 1px solid #efefef;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  position: relative;
}

.makeup {
  margin-top: 8px;
  background-color: #fff;
  padding: 0 15px 15px;
}
.makeup .head {
  padding: 15px 0;
  font-weight: 700;
}
.makeup .effect-col, .makeup .risk-col {
  background-color: #f6f6f6;
  padding: 10px;
  border-radius: 10px;
  line-height: 35px;
}
.makeup .value {
  float: right;
  padding-right: 10px;
}

.all-makeup {
  margin-top: 8px;
  background-color: #fff;
}
.all-makeup .head {
  padding: 15px;
  position: relative;
  font-weight: 700;
}
.all-makeup .head .help {
  position: absolute;
  right: 15px;
  top: 19px;
  font-size: 16px;
}
.makeup-table {
  width: 100%;
  border: 1px solid #efefef;
  border-collapse: collapse;
  border-left: none;
  border-right: none;
  text-align: center;
}
.makeup-table tr {
  border: 1px solid #efefef;
}
.makeup-table tr th {
  height: 50px;
  font-size: 14px;
  border: 1px solid #efefef;
}
.makeup-table tr td {
  font-size: 14px;
  padding: 10px 0;
  border: 1px solid #efefef;
}
.makeup-table tr td:last-child, .makeup-table tr th:last-child{
  border-right: none;
  padding-right: 10px;
}
.makeup-table tr td:first-child, .makeup-table tr th:first-child{
  border-left: none;
  padding-left: 16px;
  text-align: left;
}
.makeup-table tr:nth-child(odd) {
  background-color: #fafafa;
}
.makeup-table .col1 {
  width: 55%;
  line-height: 22px;
}
.makeup-table .col2 {
  width: 15%;
}
.makeup-table .col3 {
  width: 15%;
}
.makeup-table .col4 {
  width: 15%;
}
.makeup-table .col5 {
  width: 30%;
}
.makeup-table .desc {
  font-size: 12px;
  color: #888;
  line-height: 18px;
  margin-top: 2px;
}

.extra-info {
  margin-top: 20px;
}
.extra-info .row {
  padding: 10px 10px;
  line-height: 30px;
  vertical-align: middle;
}
.extra-info .label {
  text-align: left;
  padding: 0 5px;
}
.extra-info .content {
  text-align: left;
  color: #000;
}



</style>
